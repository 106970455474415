import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import BEMHelper from "react-bem-helper";
import Helmet from "react-helmet";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderSolidColor from "../modules/headers/header-solid-color";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";

import { richTextConfig } from "../rich-text-config/rich-text-config";

import articleFallbackImg from "../assets/img/patterns/article-fallback.png";

const bem = new BEMHelper({
  name: "template-contact-thanks",
});

const MainThanks = ({ data, location, pageContext }) => {
  const page = data.contentfulTemplateMainPage;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  const metaImage = page.seoShareImage ? page.seoShareImage.file.url : null;

  return (
    <Layout location={location}>
      <SEO
        title={page?.seoPageTitle}
        description={
          page.seoPageDescription?.seoPageDescription ||
          "Let's connect! Learn more about how UnitedLex can help solve your legal and business challenges or sign up to receive our thought leadership today."
        }
        image={metaImage}
        url={location?.href}
      />
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <HeaderSolidColor
        headerTitle={page?.seoPageTitle || null}
        headerDescription={page?.seoPageDescription?.seoPageDescription || null}
      />
      <div {...bem()}>
        <div {...bem("main-content-wrapper")}>
          <article {...bem("body")}>
            <div {...bem("body-copy-wrapper")}>
              {page?.addForm &&
              page?.addForm?.successMessage &&
              documentToReactComponents(
                JSON.parse(page?.addForm?.successMessage?.raw),
                richTextConfig()
              )}
            </div>
          </article>
          <aside {...bem("aside")}>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default MainThanks;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateMainPage(slug: { eq: $slug }) {
      slug
      doNotIndex
      seoPageTitle
      seoPageDescription {
        seoPageDescription
      }
      seoShareImage {
        ...ContentfulAssetFragment
      }
      addForm {
        contentful_id
        formId
        formHeader {
          raw
        }
        successMessage {
          raw
        }
      }
    }
  }
`;